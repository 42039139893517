@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@600&family=League+Spartan:wght@400;500&display=swap');

.section.intro {
    position: relative;
    height: 800px; /* Match Figma frame height */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.section.intro.gradient-overlay::after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('../img/introBg.png'); /* or use the path variable dynamically if using SCSS */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2; /* 👈 20% opacity */
    z-index: 1;
  }
  
.section.intro.gradient-overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(195deg, #007B43 0%, #000404 100%);
    backdrop-filter: blur(2px);
    color: #000000;
    opacity: 1;
    z-index: 0;
    pointer-events: none;
  }
  
  .section.intro.gradient-overlay > * {
    position: relative;
    z-index: 2;
  }
 
  
  .transparent-bg {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);
    color: #000000;
    
    margin: 0 auto;
    padding: 14px;
    border-radius: 8px;
    line-height: 1.7;
  }

  .transparent-bg-without-padding {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);
    color: #000000;
    
    margin: 0 auto;
    border-radius: 8px;
    line-height: 1.7;
  }
  
.scholarships-page {
  font-family: 'League Spartan';
  color: #1d1d1d;
  background-color: #f7f7f7;
  padding: 40px 20px;
}

.section {
  margin-bottom: 60px;
}

.section-title {
    font-family: 'Cinzel';
    font-size: 33px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
  }

  .section-title-start {
    font-family: 'Cinzel-Regular';
    color: #ffffff;
  }

  .title-font {
    font-size: 60px;
    line-height: 60px;
  }

  .mobile-font {
    font-size: 33px;
  }
  
  .contactus-background {
    background-image: url('../img/scholars-program/contactUs.webp');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
    
  }
  .contactus-mobile {
    background-image: url('../img/scholars-program/mobileContactus.webp');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
  }

  .intro-view {
    background-image: url('../img/scholars-program/intro.webp');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
  }
 
  .section-title.green {
    font-family: 'Cinzel';
    font-size: 33px;
    color: #0D3C42;
    margin-bottom: 20px;
    text-align: center;
  }

  .mindset-vision-view {
    background-image: url('../img/scholars-program/mindsetVision.webp');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
  }

  .eligblity-view {
    background-image: url('../img/scholars-program/eligiblityView.webp');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
  }

.section-box {
 
  color: #fff;
  padding: 28px;
  border-radius: 20px;
  line-height: 1.6;
  backdrop-filter: blur(4px);
  border: 1px solid #ffffff50;
}

.section-body {
    font-family:'LeagueSpartan-Light';
    
    color: #fff;
    text-align:center
}

.section-body-without-center {
  font-family:'LeagueSpartan-Light';
  color: #fff;
}

.objectives-content {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: flex-start;
}

.objectives-img {
  
  border-radius: 8px;
  object-fit: cover;
}

.objectives-list {
  list-style: none;
  padding: 0;
  font-size: 18px;
}

.objectives-list li {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.bullet {
  font-size: 20px;
  margin-right: 12px;
}

.mission-vision .section-box {
  margin-bottom: 20px;
}

.section.mission-vision {
  position: relative;
  height: 800px; /* Match Figma frame height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section.mission-vision.gradient-overlay::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: url('../img/introBg.png'); /* or use the path variable dynamically if using SCSS */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2; /* 👈 20% opacity */
  z-index: 1;
}

.section.mission-vision.gradient-overlay::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(195deg, #007B43 0%, #000404 100%);
  backdrop-filter: blur(2px);
  color: #000000;
  opacity: 1;
  z-index: 0;
  pointer-events: none;
}

.section.mission-vision.gradient-overlay > * {
  position: relative;
  z-index: 2;
}

.section.objectives {
  position: relative;
  height: 800px; /* Match Figma frame height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.program-structure .program-grid,
.eligibility .eligibility-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.program-box,
.eligibility-box {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
}

.program-box.gradient-overlay::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(195deg, #007B43 0%, #000404 100%);
  backdrop-filter: blur(2px);
  color: #000000;
  opacity: 1;
  z-index: 1;
  pointer-events: none;
}

.program-box.gradient-overlay > * {
  position: relative;
  z-index: 2;
}

.section-subtitle {
  color: #fff;
  margin-bottom: 24px;
  font-size: 18px;
  font-family: 'League Spartan';
}

.section-subtitle.green {
  color: #0D3C42;
  margin-bottom: 24px;
  font-size: 18px;
  font-family: 'League Spartan';
}

.mission-vision .section-box {
  margin-bottom: 20px;
}

.section.mission-vision {
  position: relative;
  height: 800px; /* Match Figma frame height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section.mission-vision.gradient-overlay::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: url('../img/introBg.png'); /* or use the path variable dynamically if using SCSS */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2; /* 👈 20% opacity */
  z-index: 1;
}

.section.mission-vision.gradient-overlay::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(195deg, #007B43 0%, #000404 100%);
  backdrop-filter: blur(2px);
  color: #000000;
  opacity: 1;
  z-index: 0;
  pointer-events: none;
}

.section.mission-vision.gradient-overlay > * {
  position: relative;
  z-index: 2;
}