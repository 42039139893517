:root {
    --white: #fff;
    --orange: #f36c00;
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    cursor: pointer;
}

img {
    max-width: 100%;
}

.outer-container {
    max-width: 100%;
    margin: auto;
    display: flex;
    overflow: hidden;
}



.outer-container .carousel-wrapper {
    z-index: 999;
    display: flex;
    flex-direction: column;
    width: 60%;
    background-repeat: no-repeat;
    background-size: 100%;
}

.content-inner-icon-image-change-tab img {
    width: 100%;
}

.outer-container .carousel-wrapper p,
.carousel-item {
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
    line-height: 1.3;
}

.outer-container .content {
    background: var(--white);
    width: 40%;
}

.leading-text {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 6px;
}

.carousel {
    height: 100%;
    display: flex;
    flex: 5;
    align-items: center;
    color: var(--white);
}

.carousel-button {
    display: flex;
    align-self: center;
    padding: 10px;
    max-width: 50px;
    border-radius: 30px;
    background: var(--orange);
    border: 2px solid var(--white);
}

.carousel-button svg {
    height: 26px;
    width: 26px;
}

.carousel-button path {
    fill: var(--white);
}

.slides {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
}

.carousel-inner {
    position: relative;
    height: 560px;
}



.carousel-inner>.active {
    font-family: LeagueSpartan-Bold;
    color: #adcf62 !important;
    font-size: 120px;
    width: 100%;
    text-align: center;
}

.carousel-item {
    font-family: LeagueSpartan-Bold;
    color: #595c5d;
    font-size: 120px;
    width: 100%;
    text-align: center;
    left: 0px;
}

.carousel-inner::after {
    bottom: 0;

}

.carousel-item {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    top: 210px;
    transition: transform 0.4s ease, opacity 0.4s ease;
}



.carousel-item.visible {
    opacity: 1;
    color: #ffffff87;
}